@import url(https://fonts.googleapis.com/css?family=Lato:300|Montserrat:700,400);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.amplify-button[data-variation='primary'] {
  background: #f69223;
}

.amplify-button[data-variation='primary']:hover {
  background: #8ac541;
}

.login > div > div::before {
  content: url('https://cs-stores-images.s3.amazonaws.com/marca.png');
  width: fit-content;
  height: fit-content;
  display: block;
  margin: 0 auto;
}
.login > div > div {
  border-radius: 0.5rem;
  overflow: hidden;
  padding-top: 2rem;
}
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.loader-spin {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #8ac541; /* Blue */
  border-radius: 30px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.anchor-btn {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.textHome{
  font-family: Montserrat, Helvetica, Times, serif;
  font-size: 1.5rem;
  margin: 0 2rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: .15rem;
  text-align: center;
  color: #F59323;
  cursor: grab;
}
.textHome:hover {
  color:#8ac541;
}


